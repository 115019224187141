import { FirebaseError, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, AuthErrorCodes } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";

const config = {
  apiKey: "AIzaSyAEEvtIfPFSd7TgDxi__6RrhFCFOp3avas",
  authDomain: "rt-archive.firebaseapp.com",
  projectId: "rt-archive",
  storageBucket: "rt-archive.appspot.com",
  messagingSenderId: "19664756228",
  appId: "1:19664756228:web:63371c6e3971729a65df51",
  measurementId: "G-LL6H6E7SWC",
  databaseURL: "https://rt-archive-default-rtdb.firebaseio.com",
};

export const app = initializeApp(config);
export const analytics = getAnalytics(app);
export const auth = getAuth();
export const db = getFirestore();
export const database = getDatabase();
export const storage = getStorage();
export const functions = getFunctions();

export const firebaseErrorMessage = (
  error: FirebaseError,
  default_message: string
) => {
  switch (error.code) {
    case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
      return "It has been too long since you logged in. Please login again and retry!";
    case AuthErrorCodes.WEAK_PASSWORD:
      return "Your password is too weak! Please try a longer password.";
    default:
      console.log(error.code);
      return default_message;
  }
};

export const uploadFile = async (
  path: string,
  file: Blob | Uint8Array | ArrayBuffer
) => {
  const storageRef = ref(storage, path);
  const snapshot = await uploadBytes(storageRef, file);
  return snapshot;
};

export const getFileURL = async (path: string) => {
  const storageRef = ref(storage, path);
  return await getDownloadURL(storageRef);
};
