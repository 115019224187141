import { Badge, Image, ImageProps } from "@nextui-org/react";
import { ChannelID } from "../util/channels";
import useSubscribedChannels from "../util/useSubscribedChannels";

export default function ChannelIcon({
  channelID,
  size,
  showBadge,
  className,
  onClick,
  ...imageProps
}: {
  channelID: ChannelID;
  size?: "xxs" | "xs" | "sm" | "md" | "lg";
  showBadge?: boolean;
} & Omit<ImageProps, "src" | "alt">) {
  const { getSubscription } = useSubscribedChannels();
  size = size ?? "md";
  const sizeClass =
    size === "xxs"
      ? "size-[16px]"
      : size === "xs"
      ? "size-[24px]"
      : size === "sm"
      ? "size-[40px]"
      : size === "md"
      ? "size-[64px]"
      : "size-[180x]";
  const subscription = getSubscription(channelID);
  return (
    <Badge
      size={size === "md" || size === "lg" ? size : "sm"}
      isInvisible={!subscription || showBadge !== true}
      content={subscription && `-${subscription[1]}`}
      color="warning"
      placement="top-left"
      shape="rectangle"
    >
      <Image
        {...imageProps}
        onClick={onClick}
        className={`${className} ${
          onClick ? "cursor-pointer" : ""
        } ${sizeClass} rounded-full`}
        src={`https://rtarchive.org/images/channels/big/${channelID}.jpg`}
        alt={channelID}
      />
    </Badge>
  );
}
