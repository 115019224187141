import { useEffect, useRef, useState } from "react";
import { ChannelID, Video } from "./util/channels";
import useSubscribedFeed from "./util/useSubscribedFeed";
import VideoPlayer from "./player/VideoPlayer";
import VideoCard from "./video/VideoCard";
import useDarkMode from "use-dark-mode";
import ChannelBar from "./channel/ChannelBar";
import { Spinner } from "@nextui-org/react";
import { Image } from "@nextui-org/react";
import useSubscribedChannels from "./util/useSubscribedChannels";
import InfiniteScroll from "react-infinite-scroll-component";

function App() {
  const [filter, setFilter] = useState<ChannelID | undefined>();

  const [selectedVideo, setSelectedVideo] = useState<Video | undefined>();

  const { feed, loadVideos, loading, refresh } = useSubscribedFeed(filter);

  const { subscribed } = useSubscribedChannels();

  const scrollableDiv = useRef<HTMLDivElement>(null);

  const { value: darkMode } = useDarkMode(undefined, {
    classNameDark: "dark",
    classNameLight: "light",
  });

  useEffect(() => {
    const timer = setInterval(() => {
      if (!loading && subscribed.length > 0 && scrollableDiv.current) {
        const { scrollHeight, clientHeight, scrollTop } = scrollableDiv.current;
        if (scrollHeight / 1.1 <= scrollTop + clientHeight) {
          loadVideos();
        }
      }
    }, 100);
    return () => clearInterval(timer);
  }, [loading, loadVideos, subscribed.length]);

  return (
    <div className="App">
      <VideoPlayer
        video={selectedVideo}
        onClose={() => setSelectedVideo(undefined)}
      />
      <div
        className="flex flex-col h-screen overflow-auto"
        id="scrollableDiv"
        ref={scrollableDiv}
      >
        <ChannelBar currentFilter={filter} onFilterChange={setFilter} />
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.5}
          pullDownToRefresh={true}
          next={loadVideos}
          refreshFunction={refresh}
          hasMore={subscribed.length > 0}
          dataLength={feed.length}
          loader={
            loading && <Spinner className="my-4 flex flex-row justify-center" />
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4 p-4">
            {feed?.map((video) => (
              <VideoCard
                key={video.id}
                video={video.data()}
                onPress={setSelectedVideo}
              />
            ))}
          </div>
        </InfiniteScroll>
        {!loading && subscribed.length === 0 && (
          <div className="items-center text-center flex flex-col gap-4 text-lg m-4">
            <p className="font-bold text-4xl">Experience</p>
            <Image
              src="https://assets.roosterteeth.com/static/media/old-school-logo.3a949b0b.png"
              className={`w-80 ${darkMode ? "invert-0" : "invert"}`}
              radius="none"
            />
            <p className="font-bold text-4xl">all over again.</p>
            <p className="max-w-prose text-default-900">
              Subscribe to your favorite eras of Rooster Teeth and return each
              day to see the latest videos in your feed! Scroll through and
              select the channels above to get started!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
