import { SubscribedChannel } from "./useSubscribedChannels";

export const getChannelTimestamp = (
  channel: SubscribedChannel,
  date: Date | null = null
) => {
  const tempDate = new Date(date ?? new Date());
  tempDate.setFullYear(tempDate.getFullYear() - channel[1]);
  return tempDate.getTime();
};

export const getFeedTime = (
  date: number,
  subscription: SubscribedChannel | undefined
) => {
  const tempDate = new Date(date);
  const years = subscription?.[1] ?? 0;
  tempDate.setFullYear(tempDate.getFullYear() + years);
  return tempDate.getTime();
};

export const getVideoFeedTime = (
  video: Video,
  subscription: SubscribedChannel | undefined
) => {
  return getFeedTime(video.date, subscription);
};

export const getYearsSinceFirstUpload = (channel: Channel) => {
  const firstUpload = new Date(channel.firstUpload);
  const now = new Date();
  return now.getFullYear() - firstUpload.getFullYear();
};

export type Feed = {
  name: string;
  timestamp: () => number;
};

export type Channel = {
  name: string;
  url?: string | Array<string>;
  channelid?: string | Array<string>;
  uploadsid?: string | Array<string>;
  rtshow?: boolean;
  platforms: Array<"youtube" | "roosterteeth">;
  friends_of_rt?: boolean;
  firstUpload: number;
};

export type Video = {
  id: string;
  likeCount: number;
  caption_tracks: { id: string; languageCode: string; name: string }[];
  title: string;
  live: boolean;
  comments: number;
  category: ChannelID;
  duplicate: boolean;
  ts: boolean;
  source: string;
  viewCount?: number;
  commentCount: number;
  tags: string[];
  date: number;
  duration: number;
  channelid: string;
  transcribed: true;
  shows: string[];
  description: string;
  ai_id: string;
  has_transcript: boolean;
  source_partial?: boolean;
  platform: "youtube" | "roosterteeth";
};

export type ChannelID = keyof typeof channels;

const channels = {
  "achievement-hunter": {
    name: "Achievement Hunter",
    url: "https://www.youtube.com/@AchievementHunter",
    channelid: "UCsB0LwkHPWyjfZ-JwvtwEXw",
    uploadsid: "UUsB0LwkHPWyjfZ-JwvtwEXw",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1217519377000,
  },
  "letsplay-community": {
    name: "LetsPlay Community",
    url: "https://www.youtube.com/@AHCommunityVids",
    channelid: "UCwm59NePcctHtfYanzfyQEQ",
    uploadsid: "UUwm59NePcctHtfYanzfyQEQ",
    platforms: ["youtube"],
    firstUpload: 1281394229000,
  },
  "all-good-no-worries": {
    name: "All Good No Worries",
    url: "https://www.youtube.com/@allgoodnoworries",
    channelid: "UCbuNpvt90jmf-UFUm4m_7cA",
    uploadsid: "UUbuNpvt90jmf-UFUm4m_7cA",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1472787000000,
  },
  "annual-pass": {
    name: "Annual Pass",
    url: "https://www.youtube.com/@annualpass",
    channelid: "UCNRVGOZOPIwZM0TNRDzLcQQ",
    uploadsid: "UUNRVGOZOPIwZM0TNRDzLcQQ",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1617894000000,
  },
  "black-box-down": {
    name: "Black Box Down",
    url: "https://www.youtube.com/@Blackboxdown",
    channelid: "UCKd0BvvBwat_gVg07QSj_Nw",
    uploadsid: "UUKd0BvvBwat_gVg07QSj_Nw",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1587020400000,
  },
  "best-friends-today": {
    name: "Best Friends Today",
    url: "https://www.youtube.com/@todayfriendsbest",
    channelid: "UCX-7r3bdEBVf0nNgrMwpb3g",
    uploadsid: "UUX-7r3bdEBVf0nNgrMwpb3g",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1693339200000,
  },
  "camp-camp": {
    name: "Camp Camp",
    url: "https://www.youtube.com/show/SC4sJ0RAB1GFdgbN6iJg2Ing",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1465520400000,
  },
  "death-battle": {
    name: "Death Battle",
    url: "https://www.youtube.com/@deathbattle",
    channelid: "UCB9_VH_CNbbH4GfKu8qh63w",
    uploadsid: "UUB9_VH_CNbbH4GfKu8qh63w",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1291701996000,
  },
  dogbark: {
    name: "Dogbark",
    url: "https://www.youtube.com/@dogbarkshow",
    channelid: "UC5_j1u4vdUFKiqDlqunGsrA",
    uploadsid: "UU5_j1u4vdUFKiqDlqunGsrA",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1695045629000,
  },
  "face-jam": {
    name: "Face Jam",
    url: "https://www.youtube.com/@facejam",
    channelid: "UCyWC7jMQuep7QDyJeb5XIrA",
    uploadsid: "UUyWC7jMQuep7QDyJeb5XIrA",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1570802400000,
  },
  "f-kface": {
    name: "F**KFACE",
    url: "https://www.youtube.com/@fckfacepod",
    channelid: "UCPP6JksBPYY3MeFQn5yanIw",
    uploadsid: "UUPP6JksBPYY3MeFQn5yanIw",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1591167600000,
  },
  funhaus: {
    name: "Funhaus",
    url: "https://www.youtube.com/@funhaus",
    channelid: "UCboMX_UNgaPBsUOIgasn3-Q",
    uploadsid: "UUboMX_UNgaPBsUOIgasn3-Q",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1423173553000,
  },
  "funhaus-too": {
    name: "Funhaus Too",
    url: "https://www.youtube.com/@FunhausToo",
    channelid: "UCQ_xcn4HfvJnKGRk1D-eRaw",
    uploadsid: "UUQ_xcn4HfvJnKGRk1D-eRaw",
    platforms: ["youtube"],
    firstUpload: 1584064920000,
  },
  gamefails: {
    name: "GameFails",
    url: "https://www.youtube.com/@gamefails",
    channelid: "UCVyr0QjyHKZQP4ej0YI1Apg",
    uploadsid: "UUVyr0QjyHKZQP4ej0YI1Apg",
    platforms: ["youtube"],
    firstUpload: 1309301121000,
  },
  "game-kids": {
    name: "Game Kids",
    url: "https://www.youtube.com/@gamekids",
    channelid: "UCf3o1ZJgHfn7kTmazZKC0iA",
    uploadsid: "UUf3o1ZJgHfn7kTmazZKC0iA",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1417194131000,
  },
  "hypothetical-nonsense": {
    name: "Hypothetical Nonsense",
    url: "https://www.youtube.com/@HypotheticalNonsense",
    channelid: "UCNfUCu-RNxPTrH8IvYivxDA",
    uploadsid: "UUNfUCu-RNxPTrH8IvYivxDA",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1693850402000,
  },
  "inside-gaming": {
    name: "Inside Gaming",
    url: "https://www.youtube.com/@insidegaming",
    channelid: "UC4w_tMnHl6sw5VD93tVymGw",
    uploadsid: "UU4w_tMnHl6sw5VD93tVymGw",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1395085002000,
  },
  "kinda-funny": {
    name: "Kinda Funny",
    rtshow: true,
    platforms: ["roosterteeth"],
    firstUpload: 1547917440000,
  },
  letsplay: {
    name: "LetsPlay",
    url: "https://www.youtube.com/@Letsplay",
    channelid: "UCkxctb0jr8vwa4Do6c6su0Q",
    uploadsid: "UUkxctb0jr8vwa4Do6c6su0Q",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1366055327000,
  },
  "red-vs-blue-universe": {
    name: "Red vs. Blue",
    rtshow: true,
    platforms: ["roosterteeth"],
    firstUpload: 1096909020000,
  },
  "red-web": {
    name: "Red Web",
    url: "https://www.youtube.com/@RedWebPod",
    channelid: "UC54VR-KYqGY7hbSxGJAkvOg",
    uploadsid: "UU54VR-KYqGY7hbSxGJAkvOg",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1594044000000,
  },
  "rooster-teeth": {
    name: "Rooster Teeth",
    url: [
      "https://www.youtube.com/@RoosterTeeth",
      "https://www.youtube.com/@roosterteethanimation",
      "https://www.youtube.com/playlist?list=PLX2gBHeHOmygJaB04nh9m9gj9XdcdWNSD",
    ],
    channelid: ["UCzH3iADRIq1IJlIXjfNgTpA", "UCII0hP2Ycmhh5j8lS4cexBQ"],
    uploadsid: ["UUzH3iADRIq1IJlIXjfNgTpA", "UUII0hP2Ycmhh5j8lS4cexBQ"],
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1100122018000,
  },
  "rooster-teeth-podcast": {
    name: "Rooster Teeth Podcast",
    url: "https://www.youtube.com/@RoosterTeethPodcast",
    channelid: "UCyoFGdNz4k7i8R0gtSoTZrA",
    uploadsid: "UUyoFGdNz4k7i8R0gtSoTZrA",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1228845600000,
  },
  "screwattack-live": {
    name: "ScrewAttack Live",
    url: "https://www.youtube.com/@ScrewAttackLive",
    channelid: "UCwC2o1O-vUQQAYMSYVnc3gg",
    uploadsid: "UUwC2o1O-vUQQAYMSYVnc3gg",
    platforms: ["youtube"],
    firstUpload: 1412658755000,
  },
  screwattacknews: {
    name: "ScrewAttackNews",
    url: "https://www.youtube.com/@screwattacknews",
    channelid: "UCg5fVYpRaZonv50NIyBqBAg",
    uploadsid: "UUg5fVYpRaZonv50NIyBqBAg",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1301652000000,
  },
  sportsball: {
    name: "Sportsball",
    url: "https://www.youtube.com/@Sportsball",
    channelid: "UCY0Uo-qhLQIL1jtBvoA0yXQ",
    uploadsid: "UUY0Uo-qhLQIL1jtBvoA0yXQ",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1440612000000,
  },
  "rwby-universe": {
    name: "RWBY",
    rtshow: true,
    platforms: ["roosterteeth"],
    firstUpload: 1352310600000,
  },
  "tales-from-the-stinky-dragon": {
    name: "Tales From the Stinky Dragon",
    url: "https://www.youtube.com/@stinkydragonpod",
    channelid: "UCRgIiwmdDoT_es43LbFxWKQ",
    uploadsid: "UURgIiwmdDoT_es43LbFxWKQ",
    rtshow: true,
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1618930800000,
  },
  "sugar-pine-7": {
    name: "Sugar Pine 7",
    url: "https://www.youtube.com/@sugarpine7",
    channelid: "UCEY0yxj6QxG4RBVRSe5orig",
    uploadsid: "UUEY0yxj6QxG4RBVRSe5orig",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1459020341000,
  },
  other: {
    name: "Other",
    url: "https://www.youtube.com/@rtlabs7937",
    channelid: "UCd76vMpxp8KjP09KPOsjsDQ",
    uploadsid: "UUd76vMpxp8KjP09KPOsjsDQ",
    platforms: ["youtube", "roosterteeth"],
    firstUpload: 1186254291000,
  },
  yogscast: {
    name: "The Yogscast",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1578063600000,
  },
  "cow-chop": {
    name: "Cow Chop",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1470517200000,
  },
  thevalleyfolk: {
    name: "TheValleyfolk",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1567441860000,
  },
  "super-carlin-brothers": {
    name: "Super Carlin Brothers",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1607106600000,
  },
  comicstorian: {
    name: "Comicstorian",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1604418466000,
  },
  "the-ship-it-show": {
    name: "The Ship-it Show",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1604419168000,
  },
  "podcast-but-outside": {
    name: "Podcast But Outside",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1604419108000,
  },
  woolieversus: {
    name: "WoolieVersus",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1600703983000,
  },
  whatsgoodgames: {
    name: "What's Good Games",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1600811722000,
  },
  "the-stuff-dreams-are-made-of": {
    name: "The Stuff Dreams Are Made Of",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1602863700000,
  },
  "high-rollers-dnd": {
    name: "High Rollers DnD",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1588354425000,
  },
  "easy-allies": {
    name: "Easy Allies",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1578668400000,
  },
  "jt-music": {
    name: "JT Music",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1518413400000,
  },
  "hat-films": {
    name: "Hat Films",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1578063600000,
  },
  recreyo: {
    name: "Recryo",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1600268400000,
  },
  cypherden: {
    name: "CypherDen",
    platforms: ["roosterteeth"],
    friends_of_rt: true,
    firstUpload: 1550479727000,
  },
};

export default channels as Record<ChannelID, Channel>;
