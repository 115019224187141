import { useEffect, useState } from "react";
import channels, { Video, getVideoFeedTime } from "../util/channels";
import { Button, Card, CardBody, CardHeader, Tooltip } from "@nextui-org/react";
import { usePreventScroll } from "@react-aria/overlays";
import ReactPlayer from "react-player/lazy";
import useSubscribedChannels from "../util/useSubscribedChannels";
import ChannelIcon from "../channel/ChannelIcon";
import { ReactComponent as ChevronDown } from "../icons/chevron-down.svg";
import { ReactComponent as Play } from "../icons/play.svg";
import { ReactComponent as Pause } from "../icons/pause.svg";
import { ReactComponent as Close } from "../icons/close.svg";

export default function VideoPlayer({
  video,
  onClose,
}: {
  video?: Video;
  onClose: () => void;
}) {
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [isMuted, setIsMuted] = useState<boolean>(false);

  useEffect(() => {
    setIsMinimized(false);
    setIsPlaying(true);
  }, [video]);

  const isDisabled = !video;

  usePreventScroll({ isDisabled: isDisabled || isMinimized });
  const { getSubscription } = useSubscribedChannels();

  const cardClass = isMinimized
    ? "fixed z-50 w-full max-w-2xl -translate-x-1/2 bottom-4 left-1/2"
    : "fixed z-50 w-full max-w-screen-lg -translate-x-1/2 top-4 left-1/2 max-h-[90vh]";

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsMinimized(true);
        isMinimized && onClose();
      }
      if (e.key === "k") {
        setIsPlaying((playing) => !playing);
      }
      if (e.key === "m") {
        setIsMuted((muted) => !muted);
      }
      if (e.key === "f") {
        setIsMinimized((minimized) => !minimized);
      }
    };
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [isMinimized, onClose]);

  if (isDisabled) {
    return null;
  }

  const url =
    video.platform === "youtube"
      ? `https://www.youtube.com/watch?v=${video.ai_id
          .split("-")
          .slice(1)
          .join("-")}`
      : video.source;

  const thumbnail =
    video.platform === "youtube"
      ? `https://img.youtube.com/vi/${video.id}/mqdefault.jpg`
      : `https://cdn.sp7archive.org/file/rtarchive/roosterteeth_thumbnails/${video.id}.jpg`;

  const videoInfo = (
    <div className="flex flex-col">
      <h4 className="font-bold text-large">{video.title}</h4>
      <div className="flex row items-center gap-1">
        <ChannelIcon channelID={video.category} size="xxs" />
        <p className="text-tiny uppercase font-bold">
          {channels[video.category].name}
        </p>
      </div>
      <Tooltip
        content={new Date(video.date).toDateString()}
        placement="bottom-start"
        closeDelay={0}
      >
        <small className="text-default-500 text-start">
          {new Date(
            getVideoFeedTime(video, getSubscription(video.category))
          ).toDateString()}
        </small>
      </Tooltip>
    </div>
  );

  return (
    <>
      {!isMinimized && (
        <div
          className="w-screen z-40 h-screen fixed overflow-auto bg-overlay/50 backdrop-opacity-disabled"
          onClick={() => setIsMinimized((minimized) => !minimized)}
        />
      )}
      <Card className={cardClass}>
        {!isMinimized && (
          <CardHeader className="pb-0 px-4 flex-row items-start justify-between">
            {videoInfo}
            <Button
              isIconOnly
              variant="flat"
              onPress={() => setIsMinimized((minimized) => !minimized)}
            >
              <ChevronDown className="stroke-current" />
            </Button>
          </CardHeader>
        )}
        <CardBody
          className="flex flex-row items-start gap-4"
          onClick={() => setIsMinimized(false)}
        >
          <div
            className={
              isMinimized
                ? "min-w-24 max-w-24 aspect-square overflow-hidden bg-cover"
                : "w-full"
            }
            style={{ backgroundImage: `url(${thumbnail})` }}
          >
            <div
              className={
                isMinimized
                  ? isPlaying
                    ? "aspect-video h-full pointer-events-none"
                    : "hidden"
                  : "aspect-video"
              }
            >
              <ReactPlayer
                url={url}
                playing={isPlaying}
                playsinline={true}
                onPause={() => setIsPlaying(false)}
                onPlay={() => setIsPlaying(true)}
                onEnded={() => setIsMinimized(true)}
                muted={isMuted}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
          </div>
          {isMinimized && (
            <div className="flex justify-between w-full">
              <div className="min-h-24">{videoInfo}</div>
              <div className="flex gap-4 self-center">
                <Button
                  isIconOnly
                  variant="flat"
                  onPress={() => setIsPlaying((playing) => !playing)}
                >
                  {isPlaying ? (
                    <Pause className="stroke-current" />
                  ) : (
                    <Play className="stroke-current" />
                  )}
                </Button>
                <Button isIconOnly variant="flat" onPress={onClose}>
                  <Close className="stroke-current" />
                </Button>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}
