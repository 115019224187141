import { useLocalStorage } from "@uidotdev/usehooks";
import { ChannelID } from "./channels";
import { useCallback, useEffect, useMemo, useState } from "react";

type StoredSubscribedChannels = { [channelID: string]: number };

type SubscribedChannels = SubscribedChannel[];

export type SubscribedChannel = [ChannelID, number];

export default function useSubscribedChannels(filterBy?: ChannelID): {
  subscribed: SubscribedChannels;
  subscribe: (channelID: ChannelID, numYears: number) => void;
  unsubscribe: (channelID: ChannelID) => void;
  getSubscription: (channelID: ChannelID) => SubscribedChannel | undefined;
  isSubscribed: (channelID: ChannelID) => boolean;
} {
  const [storedSubs, setStoredSubs] = useLocalStorage<StoredSubscribedChannels>(
    "subscribed",
    {}
  );

  const [subscriptions, setSubscriptions] =
    useState<StoredSubscribedChannels>(storedSubs);

  useEffect(() => {
    if (
      Object.keys(storedSubs).length !== Object.keys(subscriptions).length ||
      Object.entries(storedSubs).some(
        ([storedChannel, storedOffset]) =>
          !Object.entries(subscriptions).some(
            ([channel, offset]) =>
              storedChannel === channel && storedOffset === offset
          )
      )
    ) {
      setSubscriptions(storedSubs);
    }
  }, [storedSubs, subscriptions]);

  const subscribe = useCallback(
    (channelID: ChannelID, numYears: number) => {
      setStoredSubs((prev) => ({
        ...prev,
        [channelID]: numYears,
      }));
    },
    [setStoredSubs]
  );

  const unsubscribe = useCallback(
    (channelID: ChannelID) => {
      setStoredSubs((prev) => {
        const { [channelID]: _, ...rest } = prev;
        return rest;
      });
    },
    [setStoredSubs]
  );

  const getSubscription = useCallback(
    (channelID: ChannelID): SubscribedChannel | undefined => {
      const years = subscriptions[channelID];
      return years ? [channelID, years] : undefined;
    },
    [subscriptions]
  );

  const subscribed: SubscribedChannels = useMemo(() => {
    if (filterBy) {
      const channel = getSubscription(filterBy);
      return channel ? [channel] : [];
    }
    return Object.entries(subscriptions) as SubscribedChannels;
  }, [subscriptions, filterBy, getSubscription]);

  const isSubscribed = useCallback(
    (channelID: ChannelID): boolean => {
      return subscriptions.hasOwnProperty(channelID);
    },
    [subscriptions]
  );

  return {
    subscribed,
    subscribe,
    unsubscribe,
    getSubscription,
    isSubscribed,
  };
}
