import {
  Button,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from "@nextui-org/react";
import channels, {
  ChannelID,
  getYearsSinceFirstUpload,
} from "../util/channels";
import useSubscribedChannels from "../util/useSubscribedChannels";
import ChannelIcon from "./ChannelIcon";
import { useState } from "react";

export default function ChannelSubModal({
  channelID,
  currentFilter,
  onFilterChange,
  ...modalProps
}: Omit<ModalProps, "children" | "placement"> & {
  channelID: ChannelID;
  currentFilter?: ChannelID | undefined;
  onFilterChange?: (years: ChannelID | undefined) => void;
}) {
  const { getSubscription, isSubscribed, subscribe, unsubscribe } =
    useSubscribedChannels();
  const subscription = getSubscription(channelID);
  const channel = channels[channelID];
  const subscribed = isSubscribed(channelID);
  const yearsSinceFirstUpload = getYearsSinceFirstUpload(channel);
  const [years, setYears] = useState<number | undefined>(subscription?.[1]);
  const valid =
    years != null && years > 0 && years <= yearsSinceFirstUpload + 1;
  const isFiltered = currentFilter === channelID;
  return (
    <Modal {...modalProps} placement="top-center">
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-row gap-2 items-center">
              <ChannelIcon size="sm" channelID={channelID} />
              <div className="flex flex-row justify-between w-full pr-4">
                {channel.name}
                {subscribed && onFilterChange && (
                  <Chip
                    color="secondary"
                    className="cursor-pointer"
                    onClick={() => {
                      isFiltered
                        ? onFilterChange(undefined)
                        : onFilterChange(channelID);
                      onClose();
                    }}
                    variant={isFiltered ? "faded" : "solid"}
                  >
                    Filter Channel
                  </Chip>
                )}
              </div>
            </ModalHeader>
            <ModalBody className="flex flex-col gap-3 items-center">
              <p className="text-nowrap">Experience {channel.name}</p>
              <Input
                autoFocus
                min={1}
                max={yearsSinceFirstUpload + 1}
                className="w-fit"
                size="lg"
                inputMode="numeric"
                variant="bordered"
                type="number"
                placeholder={yearsSinceFirstUpload.toString()}
                value={years?.toString()}
                onChange={({ target }) => setYears(target.valueAsNumber)}
              />
              <p className="text-nowrap">years in the past</p>
              {!valid && (
                <p className="text-yellow-400 text-center">
                  The selected year offset is outside the range of this channel!
                </p>
              )}
            </ModalBody>
            <ModalFooter>
              {subscribed && (
                <Button
                  color="danger"
                  variant="light"
                  onPress={() => {
                    unsubscribe(channelID);
                    if (isFiltered && onFilterChange) {
                      onFilterChange(undefined);
                    }
                    onClose();
                  }}
                >
                  Unsubscribe
                </Button>
              )}
              <Button
                color="primary"
                isDisabled={!valid || years === subscription?.[1]}
                onPress={() => {
                  years && subscribe(channelID, years);
                  onClose();
                }}
              >
                Subscribe
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
