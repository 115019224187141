import { Navbar, ScrollShadow } from "@nextui-org/react";
import channels, { ChannelID } from "../util/channels";
import ChannelIcon from "./ChannelIcon";
import { useCallback, useState } from "react";
import ChannelSubModal from "./ChannelSubModal";

export default function ChannelBar({
  currentFilter,
  onFilterChange,
}: {
  currentFilter?: ChannelID | undefined;
  onFilterChange?: (years: ChannelID | undefined) => void;
}) {
  const [subModelChannelID, setSubModelChannelID] = useState<ChannelID | null>(
    null
  );

  const scrollRef = useCallback((node: HTMLElement | undefined | null) => {
    const onWheel = (e: globalThis.WheelEvent) => {
      e.preventDefault();
      node?.scrollBy({ left: Math.max(Math.min(e.deltaY, 100), -100) });
    };
    if (node == null) {
      return;
    }
    node.addEventListener("wheel", onWheel, { passive: false });
    return () => {
      node.removeEventListener("wheel", onWheel);
    };
  }, []);

  return (
    <Navbar height={80} className="z-30">
      <ScrollShadow
        hideScrollBar={true}
        orientation="horizontal"
        ref={scrollRef}
      >
        <div className="flex flex-row gap-2 items-center px-3">
          {Object.keys(channels).map((channelID) => {
            return (
              <div
                key={channelID}
                className={`shrink-0 pt-2 px-1 ${
                  currentFilter === channelID
                    ? "bg-gradient-to-r from-indigo-500 to-purple-500 "
                    : ""
                }`}
              >
                <ChannelIcon
                  className="drop-shadow"
                  onClick={() => setSubModelChannelID(channelID as ChannelID)}
                  showBadge
                  channelID={channelID as ChannelID}
                  size="md"
                />
              </div>
            );
          })}
        </div>
      </ScrollShadow>
      {subModelChannelID && (
        <ChannelSubModal
          isOpen={true}
          onClose={() => setSubModelChannelID(null)}
          channelID={subModelChannelID}
          currentFilter={currentFilter}
          onFilterChange={onFilterChange}
        />
      )}
    </Navbar>
  );
}
