import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { NextUIProvider } from "@nextui-org/react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

TimeAgo.addDefaultLocale(en);

root.render(
  <NextUIProvider>
    <App />
  </NextUIProvider>
);
