import {
  CollectionReference,
  FirestoreDataConverter,
  OrderByDirection,
  Query,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import channels, {
  ChannelID,
  Video,
  getChannelTimestamp,
  getFeedTime,
  getVideoFeedTime,
} from "./channels";
import { useCallback, useEffect, useState } from "react";
import { db } from "./firebase";
import useSubscribedChannels, {
  SubscribedChannel,
} from "./useSubscribedChannels";

const getVideoCollection = (): CollectionReference<Video> => {
  const converter: FirestoreDataConverter<Video> = {
    toFirestore: (obj: Video) => {
      const { id, ...data } = obj;
      return data;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options) => {
      const data = snapshot.data(options) as Omit<Video, "id">;
      return { ...data, id: snapshot.id } as Video;
    },
  };
  return collection(db, "videos").withConverter(converter);
};

const getVideoQuery = (
  channel: SubscribedChannel,
  options: {
    startAfter?: QueryDocumentSnapshot<Video>;
    minDate?: Date;
    orderBy?: OrderByDirection;
    limit?: number;
  } = {}
): Query<Video> => {
  const { startAfter: last, limit: max, minDate, orderBy: direction } = options;
  return query(
    getVideoCollection(),
    where("duplicate", "==", false),
    where("date", "<=", getChannelTimestamp(channel)),
    where("category", "==", channel[0]),
    where("date", ">=", minDate ? getChannelTimestamp(channel, minDate) : 0),
    orderBy("date", direction ?? "desc"),
    startAfter(last ? last : []),
    ...(max ? [limit(max)] : [])
  ) as Query<Video>;
};

const getMinDate = (date: Date = new Date()) => {
  const tempDate = new Date(date);
  const offset = 14;
  tempDate.setDate(tempDate.getDate() - offset);
  return tempDate;
};

export default function useSubscribedFeed(channel?: ChannelID) {
  const { subscribed, getSubscription } = useSubscribedChannels(channel);
  const [loading, setLoading] = useState<boolean>(false);
  const [minDate, setMinDate] = useState<Date>(getMinDate());
  const [feed, setFeed] = useState<QueryDocumentSnapshot<Video>[]>([]);
  const [videos, setVideos] = useState<{
    [channelID: string]:
      | { [videoID: string]: QueryDocumentSnapshot<Video> }
      | undefined;
  } | null>(null);
  const [loadMoreVideos, setLoadMoreVideos] = useState<boolean>(false);

  const refresh = useCallback(() => {
    setVideos(null);
    setMinDate(getMinDate());
    setFeed([]);
  }, []);

  useEffect(() => {
    refresh();
  }, [subscribed, channel, refresh]);

  const sortVideos = useCallback(
    (a: QueryDocumentSnapshot<Video>, b: QueryDocumentSnapshot<Video>) => {
      const dateA = getVideoFeedTime(
        a.data(),
        getSubscription(a.data().category)
      );
      const dateB = getVideoFeedTime(
        b.data(),
        getSubscription(b.data().category)
      );
      return dateB - dateA;
    },
    [getSubscription]
  );

  const loadVideos = useCallback(() => {
    const shouldLoadVideos = subscribed.some((channel) => {
      const firstVideo = Object.values(videos?.[channel[0]] ?? {})
        ?.sort(sortVideos)
        ?.at(-1);
      const hasFirstUpload =
        firstVideo != null &&
        firstVideo.data().date <= channels[channel[0]].firstUpload;
      return (
        getChannelTimestamp(channel) >= channels[channel[0]].firstUpload &&
        !hasFirstUpload
      );
    });
    if (shouldLoadVideos && !loading && subscribed.length > 0) {
      setLoading(true);
      setLoadMoreVideos(false);
      Promise.all(
        subscribed.map((channel) =>
          getDocs(
            getVideoQuery(channel, {
              startAfter: Object.values(videos?.[channel[0]] ?? {})
                ?.sort(sortVideos)
                ?.at(-1),
              minDate,
            })
          ).then((snapshot) => {
            setVideos((prev) => {
              const prevVideos = prev?.[channel[0]] ?? {};
              return {
                ...prev,
                [channel[0]]: {
                  ...prevVideos,
                  ...Object.fromEntries(
                    snapshot.docs.map((video) => [video.id, video])
                  ),
                },
              };
            });
            return snapshot;
          })
        )
      )
        .then((data) => {
          const loadMore =
            data.map((snapshot) => snapshot.docs).flat().length <= 0 &&
            subscribed.some(
              (channel) =>
                getFeedTime(channels[channel[0]].firstUpload, channel) <=
                minDate.getTime()
            );
          setMinDate((minDate) => getMinDate(minDate));
          setLoadMoreVideos(loadMore);
        })
        .finally(() => setLoading(false));
    }
  }, [loading, videos, subscribed, minDate, sortVideos]);

  useEffect(() => {
    if (videos == null || loadMoreVideos) {
      loadVideos();
    }
  }, [videos, loadVideos, loadMoreVideos]);

  useEffect(() => {
    if (!loading) {
      setFeed(
        subscribed
          .map((channel) => Object.values(videos?.[channel[0]] ?? {}))
          .flat()
          .sort(sortVideos)
      );
    }
  }, [videos, loading, getSubscription, subscribed, sortVideos]);

  return { feed, loadVideos, loading, refresh };
}
