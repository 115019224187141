import { Card, CardBody, CardFooter, Image, Link } from "@nextui-org/react";
import ChannelIcon from "../channel/ChannelIcon";
import channels, { Video, getVideoFeedTime } from "../util/channels";
import useSubscribedChannels from "../util/useSubscribedChannels";
import { useTimeAgo } from "react-time-ago";
import { useState } from "react";
import ChannelSubModal from "../channel/ChannelSubModal";

const getDuration = (duration: number) => {
  const isoString = new Date(duration * 1000).toISOString();
  const durationString =
    duration < 3600 ? isoString.substring(14, 19) : isoString.substring(11, 16);
  return durationString.startsWith("0")
    ? durationString.substring(1)
    : durationString;
};

export default function VideoCard({
  video,
  onPress,
}: {
  video: Video;
  onPress: (video: Video | undefined) => void;
}) {
  const { getSubscription } = useSubscribedChannels();
  const [showChannelSubModal, setShowChannelSubModal] =
    useState<boolean>(false);
  const { category, title } = video;
  const date = new Date(getVideoFeedTime(video, getSubscription(category)));
  const feedTime = useTimeAgo({ date, locale: "en-US" });
  const thumbnail =
    video.platform === "youtube"
      ? `https://img.youtube.com/vi/${video.id}/mqdefault.jpg`
      : `https://cdn.sp7archive.org/file/rtarchive/roosterteeth_thumbnails/${video.id}.jpg`;
  return (
    <div className="flex flex-col gap-4">
      <Card shadow="sm" isPressable onPress={() => onPress(video)}>
        <CardBody className="overflow-visible p-0 aspect-video">
          <Image
            shadow="sm"
            radius="lg"
            width="100%"
            height="100%"
            alt={title}
            className="w-full object-cover"
            src={thumbnail}
          />
        </CardBody>
        <div className="flex flex-row justify-end w-full h-50 absolute bottom-1 pr-1 z-10">
          <CardFooter className="bg-black py-1 rounded-large w-fit shadow-small">
            {getDuration(video.duration)}
          </CardFooter>
        </div>
      </Card>
      <div className="flex flex-row gap-2">
        <ChannelIcon
          channelID={video.category}
          size="sm"
          showBadge
          onClick={() => setShowChannelSubModal(true)}
        />
        <div className="flex flex-col gap-[2px]">
          <Link
            className="cursor-pointer"
            onPress={() => onPress(video)}
            color="foreground"
            underline="hover"
          >
            <h5 className="font-bold line-clamp-2 leading-snug">
              {video.title}
            </h5>
          </Link>
          <small className="text-default-500">
            {channels[video.category].name}
          </small>
          <small className="text-default-500">{`${
            video.viewCount
              ? `${video.viewCount.toLocaleString()} views • `
              : ""
          } ${feedTime.formattedDate}`}</small>
        </div>
      </div>
      <ChannelSubModal
        isOpen={showChannelSubModal}
        onClose={() => setShowChannelSubModal(false)}
        channelID={video.category}
      />
    </div>
  );
}
